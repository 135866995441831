export const environment = {
  baseEndpoint: 'https://api-dev.talkingstick.app/',
  buildNumber: "28858798",  
  versionNumber: "3.0.5",  
  environment_deploy: 'dev',
  rayGunKey: 'hqaZDpkENyp0WOVqpDhdg',
  authResultKey: '41cfd863-d3be-4e1c-84fe-1a6d71f620c0',
  cryptoKey: '0ef6fc19-fa40-476e-8c9c-dbc22969acab',
  posthogToken: 'phc_FxfNWKWqYxa9nm24KsWccHzMR776akapxZWqhHjFTGJ',
  orgCode: 'burns',
  locizeProjectId: '714c2f9a-6c84-4052-b7f0-1e6469e617d7',

  firebaseConfig: {
    apiKey: 'AIzaSyDye1Ufb56MVrkePYPwHUj27A0q07xypKk',
    authDomain: 'the-burns-way-dev.firebaseapp.com',
    projectId: 'the-burns-way-dev',
    storageBucket: 'the-burns-way-dev.appspot.com',
    messagingSenderId: '160913184471',
    appId: '1:160913184471:web:2ff2a7a1f1bf33fe3ac292',
    vapidKey: 'BC1KH3H0nS9B8_NV6SPNvzE88QOWEDFx_HIbz7wWPh6CHW8hTkqyLHz79EfqArfbZXRDVCLhn4g7mfU8cRlpA9k',
  },
};
